import { createAction } from 'redux-actions';
import * as Api from 'api/places';
import {
  createActionWithNestedPagination,
  createActionWithPagination,
  generatePaginators,
  getPaginator,
} from 'utils/pagination';
import { conversation, place } from 'api/schema';
import queryString from 'query-string';
import keys from 'lodash/keys';
import { SERGIC_OFFER } from '../constants';
import Paginator from '../paginator';

const placesConversationsPaginatorURI = '/v1/places/{id}/conversations';
const placesConversationsPaginatorEntity = [conversation];
const placesConversationsPaginators = generatePaginators(
  placesConversationsPaginatorURI,
  placesConversationsPaginatorEntity,
);

const requestPlaceInvitations = createAction('PLACE_INVITATIONS_REQUEST');
const receivePlaceInvitations = createAction('PLACE_INVITATIONS_SUCCESS');
const failPlaceInvitations = createAction('PLACE_INVITATIONS_ERROR');

export const getPlaceInvitations = placeId => dispatch => {
  dispatch(requestPlaceInvitations());

  return Api.getPlaceInvitations(placeId).then(
    data => dispatch(receivePlaceInvitations({ placeId, data })),
    error => dispatch(failPlaceInvitations(error)),
  );
};

const requestPlaces = createAction('PLACES_REQUEST');
const receivePlaces = createActionWithPagination('PLACES_SUCCESS');
const failPlaces = createAction('PLACES_ERROR');

export const getPaginatePlaces = (
  loadInvitations = false,
  params = {},
) => dispatch => {
  dispatch(requestPlaces());

  const paginator = new Paginator(
    `/v1/users/me/places?by_sergic_offer=${SERGIC_OFFER}`,
    [place],
    1,
    20,
  );

  keys(params).forEach(key => {
    paginator.set(key, params[key]);
  });

  return paginator.get().then(
    response => {
      if (loadInvitations) {
        response.result.forEach(placeId =>
          dispatch(getPlaceInvitations(placeId)),
        );
      }
      return dispatch(receivePlaces(response));
    },
    error => dispatch(failPlaces(error)),
  );
};

const requestPlace = createAction('PLACE_REQUEST');
const receivePlace = createAction('PLACE_SUCCESS');
const failPlace = createAction('PLACE_ERROR');

export const getPlace = id => dispatch => {
  dispatch(requestPlace(id));

  return Api.getPlace(id).then(
    response => dispatch(receivePlace(response)),
    error => dispatch(failPlace(error)),
  );
};

const requestPlaceNotificationsCount = createAction(
  'PLACE_NOTIFICATIONS_COUNT_REQUEST',
);
const receivePlaceNotificationsCount = createAction(
  'PLACE_NOTIFICATIONS_COUNT_SUCCESS',
);
const failPlaceNotificationsCount = createAction(
  'PLACE_NOTIFICATIONS_COUNT_ERROR',
);

export const getPlaceNotificationsCount = id => dispatch => {
  dispatch(requestPlaceNotificationsCount(id));

  return Api.getPlaceNotificationsCount(id).then(
    response => dispatch(receivePlaceNotificationsCount(response)),
    error => dispatch(failPlaceNotificationsCount(error)),
  );
};

const requestPlaceEquipments = createAction('PLACE_EQUIPMENTS_REQUEST');
const receivePlaceEquipments = createAction('PLACE_EQUIPMENTS_SUCCESS');
const failPlaceEquipments = createAction('PLACE_EQUIPMENTS_ERROR');

export const getPlaceEquipments = id => dispatch => {
  dispatch(requestPlaceEquipments(id));

  return Api.getPlaceEquipments(id).then(
    data =>
      dispatch(
        receivePlaceEquipments({
          id,
          data,
        }),
      ),
    error => dispatch(failPlaceEquipments({ id, error })),
  );
};

const requestEquipment = createAction('EQUIPMENT_REQUEST');
const receiveEquipment = createAction('EQUIPMENT_SUCCESS');
const failEquipment = createAction('EQUIPMENT_ERROR');

export const getEquipment = id => dispatch => {
  dispatch(requestEquipment(id));

  return Api.getEquipment(id).then(
    response => dispatch(receiveEquipment(response)),
    error => dispatch(failEquipment(error)),
  );
};

export const fetchEquipmentData = id => dispatch => {
  dispatch(getEquipment(id));
  dispatch(getEquipmentReports(id));
};

const requestPlaceReports = createAction('PLACE_REPORTS_REQUEST');
const receivePlaceReports = createAction('PLACE_REPORTS_SUCCESS');
const failPlaceReports = createAction('PLACE_REPORTS_ERROR');

export const getPlaceReports = id => dispatch => {
  dispatch(requestPlaceReports(id));

  return Api.getPlaceReports(id).then(
    data =>
      dispatch(
        receivePlaceReports({
          id,
          data,
        }),
      ),
    error => dispatch(failPlaceReports({ id, error })),
  );
};

const requestEquipmentReports = createAction('EQUIPMENT_REPORTS_REQUEST');
const receiveEquipmentReports = createAction('EQUIPMENT_REPORTS_SUCCESS');
const failEquipmentReports = createAction('EQUIPMENT_REPORTS_ERROR');

export const getEquipmentReports = id => dispatch => {
  dispatch(requestEquipmentReports(id));

  return Api.getEquipmentReports(id).then(
    data =>
      dispatch(
        receiveEquipmentReports({
          id,
          data,
        }),
      ),
    error => dispatch(failEquipmentReports({ id, error })),
  );
};

const requestPlaceBudgets = createAction('PLACE_BUDGETS_REQUEST');
const receivePlaceBudgets = createAction('PLACE_BUDGETS_SUCCESS');
const failPlaceBudgets = createAction('PLACE_BUDGETS_ERROR');

export const getPlaceBudgets = (placeId, fiscalYearId, ioni) => dispatch => {
  dispatch(requestPlaceBudgets(placeId));

  return Api.getPlaceBudgets(placeId, fiscalYearId, ioni).then(
    data => dispatch(receivePlaceBudgets({ placeId, fiscalYearId, data })),
    error => dispatch(failPlaceBudgets(error)),
  );
};

const requestPlaceFiscalYears = createAction('PLACE_FISCAL_YEARS_REQUEST');
const receivePlaceFiscalYears = createAction('PLACE_FISCAL_YEARS_SUCCESS');
const failPlaceFiscalYears = createAction('PLACE_FISCAL_YEARS_ERROR');

export const getPlaceFiscalYears = (placeId, ioni) => dispatch => {
  dispatch(requestPlaceFiscalYears(placeId));

  return Api.getPlaceFiscalYears(placeId, ioni).then(
     data => dispatch(receivePlaceFiscalYears({ placeId, data })),
     error => dispatch(failPlaceFiscalYears(error))
  );
};

const requestPlaceTreasuries = createAction('PLACE_TREASURIES_REQUEST');
const receivePlaceTreasuries = createAction('PLACE_TREASURIES_SUCCESS');
const failPlaceTreasuries = createAction('PLACE_TREASURIES_ERROR');

export const getPlaceTreasuries = placeId => dispatch => {
  dispatch(requestPlaceTreasuries({ placeId }));

  return Api.getPlaceTreasuries(placeId).then(
    data => dispatch(receivePlaceTreasuries({ placeId, data })),
    error => dispatch(failPlaceTreasuries({ placeId, error })),
  );
};

const requestPlaceMaintenanceWorks = createAction(
  'PLACE_MAINTENANCE_WORKS_REQUEST',
);
const receivePlaceMaintenanceWorks = createAction(
  'PLACE_MAINTENANCE_WORKS_SUCCESS',
);
const failPlaceMaintenanceWorks = createAction('PLACE_MAINTENANCE_WORKS_ERROR');

export const getPlaceMaintenanceWorks = placeId => dispatch => {
  dispatch(requestPlaceMaintenanceWorks(placeId));

  return Api.getPlaceMaintenanceWorks(placeId).then(
    data => dispatch(receivePlaceMaintenanceWorks({ placeId, data })),
    error => dispatch(failPlaceMaintenanceWorks(error)),
  );
};

const requestPlaceCoownershipFolders = createAction(
  'PLACE_COOWNERSHIP_FOLDERS_REQUEST',
);
const receivePlaceCoownershipFolders = createActionWithNestedPagination(
  'PLACE_COOWNERSHIP_FOLDERS_SUCCESS',
);
const failPlaceCoownershipFolders = createAction(
  'PLACE_COOWNERSHIP_FOLDERS_ERROR',
);

export const getPlaceCoownershipFolders = ({ params, sliceId }) => dispatch => {
  dispatch(requestPlaceCoownershipFolders({ params, sliceId }));

  return Api.getPlaceCoownershipFolders({ params, sliceId }).then(
    data => dispatch(receivePlaceCoownershipFolders({ params, sliceId, data })),
    error => dispatch(failPlaceCoownershipFolders({ params, sliceId, error })),
  );
};

const requestCoownershipFolderDocuments = createAction(
  'COOWNERSHIP_FOLDER_DOCUMENTS_REQUEST',
);
const receiveCoownershipFolderDocuments = createActionWithNestedPagination(
  'COOWNERSHIP_FOLDER_DOCUMENTS_SUCCESS',
);
const failCoownershipFolderDocuments = createAction(
  'COOWNERSHIP_FOLDER_DOCUMENTS_ERROR',
);

export const getCoownershipFolderDocuments = ({
  sliceId,
  params,
}) => dispatch => {
  dispatch(requestCoownershipFolderDocuments({ sliceId, params }));

  return Api.getCoownershipFolderDocuments({
    sliceId,
    params,
  }).then(
    data =>
      dispatch(
        receiveCoownershipFolderDocuments({
          params,
          sliceId,
          data,
        }),
      ),
    error =>
      dispatch(failCoownershipFolderDocuments({ sliceId, params, error })),
  );
};

const requestPlaceMaintenanceContractsCategories = createAction(
  'PLACE_MAINTENANCE_CONTRACTS_CATEGORIES_REQUEST',
);
const receivePlaceMaintenanceContractsCategories = createAction(
  'PLACE_MAINTENANCE_CONTRACTS_CATEGORIES_SUCCESS',
);
const failPlaceMaintenanceContractsCategories = createAction(
  'PLACE_MAINTENANCE_CONTRACTS_CATEGORIES_ERROR',
);

export const getPlaceMaintenanceContractsCategories = placeId => dispatch => {
  dispatch(requestPlaceMaintenanceContractsCategories(placeId));

  return Api.getPlaceMaintenanceContractsCategories(placeId).then(
    data =>
      dispatch(receivePlaceMaintenanceContractsCategories({ placeId, data })),
    error => dispatch(failPlaceMaintenanceContractsCategories(error)),
  );
};

const requestPlaceMaintenanceContract = createAction(
  'PLACE_MAINTENANCE_CONTRACTS_CATEGORY_REQUEST',
);
const receivePlaceMaintenanceContract = createAction(
  'PLACE_MAINTENANCE_CONTRACTS_CATEGORY_SUCCESS',
);
const failPlaceMaintenanceContract = createAction(
  'PLACE_MAINTENANCE_CONTRACTS_CATEGORY_ERROR',
);

export const getPlaceMaintenanceContract = (
  placeId,
  contractId,
) => dispatch => {
  dispatch(requestPlaceMaintenanceContract(contractId));

  return Api.getPlaceMaintenanceContract(contractId).then(
    data => dispatch(receivePlaceMaintenanceContract({ contractId, data })),
    error => dispatch(failPlaceMaintenanceContract(error)),
  );
};

const receivePlaceConversations = createActionWithNestedPagination(
  'PLACE_CONVERSATIONS_SUCCESS',
);
const failPlaceConversations = createAction('PLACE_CONVERSATIONS_ERROR');

export const getPlaceConversationsPage = (placeId, query = '') => dispatch => {
  const paginator = getPaginator(
    placesConversationsPaginators,
    placeId,
    placesConversationsPaginatorURI,
    placesConversationsPaginatorEntity,
  );

  const parsedQuery = queryString.parse(query);

  keys(parsedQuery).forEach(key => {
    paginator.set(key, parsedQuery[key]);
  });

  paginator.set('sort', '-updated_at');

  return paginator.get().then(
    data => dispatch(receivePlaceConversations({ placeId, data })),
    error => dispatch(failPlaceConversations(error)),
  );
};

const requestPlaceAgency = createAction('PLACE_AGENCY_REQUEST');
const receivePlaceAgency = createAction('PLACE_AGENCY_SUCCESS');
const failPlaceAgency = createAction('PLACE_AGENCY_ERROR');

export const getPlaceAgency = placeId => dispatch => {
  dispatch(requestPlaceAgency());

  return Api.getPlaceAgency(placeId).then(
    data => dispatch(receivePlaceAgency({ placeId, data })),
    error => dispatch(failPlaceAgency(error)),
  );
};

const requestPlaceRSSFeeds = createAction('PLACE_RSS_FEEDS_REQUEST');
const receivePlaceRSSFeeds = createAction('PLACE_RSS_FEEDS_SUCCESS');
const failPlaceRSSFeeds = createAction('PLACE_RSS_FEEDS_ERROR');

export const getPlaceRSSFeeds = placeId => dispatch => {
  dispatch(requestPlaceRSSFeeds());

  return Api.getPlaceRSSFeedsOld(placeId).then(
    data => {
      dispatch(receivePlaceRSSFeeds({ placeId, data }));
    },
    error => dispatch(failPlaceRSSFeeds(error)),
  );
};

const requestPostPlaceDefaultIdentities = createAction(
  'POST_PLACE_DEFAULT_IDENTITIES_REQUEST',
);
const receivePostPlaceDefaultIdentities = createAction(
  'POST_PLACE_DEFAULT_IDENTITIES_SUCCESS',
);
const failPostPlaceDefaultIdentities = createAction(
  'POST_PLACE_DEFAULT_IDENTITIES_ERROR',
);

export const postPlaceDefaultIdentities = (placeId, payload) => dispatch => {
  dispatch(requestPostPlaceDefaultIdentities());

  return Api.postPlaceDefaultIdentities(placeId, payload).then(
    data => dispatch(receivePostPlaceDefaultIdentities({ placeId, data })),
    error => dispatch(failPostPlaceDefaultIdentities(error)),
  );
};

const requestUpdatePlace = createAction('UPDATE_PLACE_REQUEST');
const receiveUpdatePlace = createAction('UPDATE_PLACE_SUCCESS');
const failUpdatePlace = createAction('UPDATE_PLACE_ERROR');

export const updatePlace = (placeId, payload) => dispatch => {
  dispatch(requestUpdatePlace());

  return Api.updatePlace(placeId, payload).then(
    data => dispatch(receiveUpdatePlace(data)),
    error => dispatch(failUpdatePlace(error)),
  );
};
