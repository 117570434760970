import { useRef, useState } from 'react';
import { Field, useField } from 'formik';
import styled, { css } from 'styled-components';
import SearchableSelectGQL from './SearchableSelectGQL';
import SegmentedControls from './SegmentedControls';
import Checkbox from './Checkbox';
import { ErrorMessage, FormField, FormLabel, Input, InputSuffix, Textarea, TextFieldIcon } from '../ui';
import { useTranslation } from 'react-i18next';
import { Attachments } from './attachments';
import { isNumber, uniqueId } from 'lodash';
import { normalizeNumber } from '../utils/validation';
import { MdRadioButtonChecked, MdRadioButtonUnchecked, MdVisibility, MdVisibilityOff, MdWarning } from 'react-icons/md';
import Select from './Select';
import { ButtonReset, Stack } from '@tymate/margaret';
import { useDeepCompareEffect } from 'react-use';

export const RadioCardDescription = styled.span`
   display: block;
   margin-top: ${({ theme }) => theme.spacing(0.5)};
`;

const LabelLabel = styled.span`
   flex: 1;
`;

const Children = styled.div`
   color: ${({ theme }) => theme.textLight};
`;

const RequiredField = styled.span`
   color: ${({ theme }) => theme.error};
   margin-left: 0.2rem;
`;

const Radio = ({ name, label, value, checked, children, onChange }) => (
   <label>
      <input type='radio' name={name} value={value} onChange={e => onChange(e, e.target.value)} />
      {children}
   </label>
);

const RadioCardWrapper = styled.label`
   display: block;
   padding: ${({ theme }) => theme.spacing()};
   border-radius: ${({ theme }) => theme.borderRadius};
   margin-bottom: ${({ theme }) => theme.spacing()};
   cursor: pointer;
   box-shadow: inset 0 0 0 1px ${({ theme }) => theme.separator};
   background-color: #fff;
   height: 100%;

   ${props =>
      props.checked &&
      css`
         box-shadow: inset 0 0 0 2px ${({ theme }) => theme.primary};
      `};

   ${props =>
      props.disabled &&
      css`
         opacity: 0.5;
         cursor: not-allowed;
      `};

   ${props =>
      props.columns &&
      css`
         flex: 1;

         + * {
            ${media.tablet`margin-left: ${({ theme }) => theme.spacing(2)}`};
         }
      `};
`;

const RadioCardLabel = styled(Stack)`
   margin-top: 0;
   margin-bottom: ${({ theme }) => theme.spacing()};
   font-weight: 600;

   svg {
      ${props =>
         props.checked &&
         css`
            color: ${({ theme }) => theme.primary};
         `};
   }

   ${props =>
      props.variant === 'bare' &&
      css`
         margin-bottom: 0;
      `};

   ${props =>
      props.variant === 'withAvatar' &&
      css`
         display: flex;
         align-items: center;
         flex-direction: row-reverse;
         margin-bottom: 0;

         svg {
            margin-right: 0;
            margin-left: ${({ theme }) => theme.spacing()};
         }

         ${LabelLabel} {
            margin-left: ${({ theme }) => theme.spacing()};
         }
      `};
`;

export const RadioCard = ({ name, label, value, children, disabled, columns, header, hideButton }) => (
   <Field name={name}>
      {({ field, form: { setFieldValue } }) => {
         const checked = field.value === value || Number(field.value) === Number(value);

         return (
            <RadioCardWrapper checked={checked} disabled={disabled} columns={columns}>
               <input type='radio' name={name} value={value} disabled={disabled} onChange={e => setFieldValue(name, e.target.value)} style={{ display: 'none' }} />

               {header}

               <RadioCardLabel checked={checked} variant={!children && 'bare'} gutterSize={1} alignY='center'>
                  {!hideButton && (checked ? <MdRadioButtonChecked /> : <MdRadioButtonUnchecked />)}
                  <LabelLabel>{label}</LabelLabel>
               </RadioCardLabel>

               <Children>{children}</Children>
            </RadioCardWrapper>
         );
      }}
   </Field>
);

const RadioWrapper = styled(Stack)`
   position: relative;
   cursor: pointer;

   + *:before {
      position: absolute;
      content: '';
      top: 0;
      left: calc(${({ theme }) => theme.spacing(2)} + 19px);
      right: 0;
      border-top: 1px solid ${({ theme }) => theme.separator};
   }
`;

const RadioLabel = styled.div`
   line-height: 1.2;
`;

const RadioIcon = styled(Stack)`
   ${({ isChecked, theme }) =>
      isChecked &&
      `
      color: ${theme.primary};
    `}
`;

export const RadioField = ({ name, label, value, disabled, columns }) => (
   <Field name={name}>
      {({ field, form: { setFieldValue } }) => {
         const checked = field.value === value || Number(field.value) === Number(value);

         return (
            <RadioWrapper checked={checked} disabled={disabled} columns={columns} as='label' padding={1} size='full'>
               <input
                  type='radio'
                  name={name}
                  value={value}
                  disabled={disabled}
                  onChange={e => setFieldValue(name, e.target.value)}
                  style={{ display: 'none' }}
                  checked={checked}
               />

               <RadioIcon alignY='center' marginRight={1} isChecked={checked}>
                  {checked ? <MdRadioButtonChecked size={19} /> : <MdRadioButtonUnchecked size={19} />}
               </RadioIcon>

               <RadioLabel>{label}</RadioLabel>
            </RadioWrapper>
         );
      }}
   </Field>
);

export const SearchableSelectGQLField = ({ label, placeholder, name, validate, ...props }) => {
   return (
      <Field name={name} validate={validate}>
         {({ field, form: { touched, errors, setFieldValue, setFieldTouched }, meta }) =>
            Boolean(label) ? (
               <FormField hasError={meta.touched && meta.error}>
                  {label && <FormLabel>{label}</FormLabel>}
                  <SearchableSelectGQL
                     {...props}
                     onChange={({ value }) => {
                        setFieldTouched(field.name);
                        setFieldValue(field.name, value);
                     }}
                  />
                  {meta.touched && meta.error && <ErrorMessage>{meta.error}</ErrorMessage>}
               </FormField>
            ) : (
               <SearchableSelectGQL
                  {...props}
                  onChange={({ value }) => {
                     setFieldTouched(field.name);
                     form.setFieldValue(field.name, value);
                  }}
               />
            )
         }
      </Field>
   );
};

const InputWrapper = styled(Stack)`
   position: relative;
`;

const PasswordViewerToggle = styled(ButtonReset)`
   position: absolute;
   top: 50%;
   right: ${({ theme }) => theme.spacing()};
   opacity: 0.5;
   transform: translateY(-50%);

   ${({ isActive }) =>
      isActive &&
      `
      opacity: 1;
    `}
`;

export const TextField = ({ label, placeholder, normalize, labelVariant, disabled, iconElement, errorVariant, required, ...props }) => {
   const [type, setType] = useState(props.type);
   const [id] = useState(uniqueId());

   return (
      <Field {...props}>
         {({ field, meta }) => {
            const shouldShowErrorIcon = errorVariant === 'icon' && meta.touched && meta.error;

            return (
               <FormField hasError={meta.touched && meta.error}>
                  {label && (
                     <FormLabel htmlFor={id}>
                        {label}
                        {required && <RequiredField>*</RequiredField>}
                     </FormLabel>
                  )}

                  <InputWrapper alignX='stretch'>
                     <Input id={id} type={type} placeholder={placeholder} {...field} disabled={Boolean(disabled)} data-cy={props['data-cy']} />
                     {Boolean(iconElement) && !shouldShowErrorIcon && iconElement}
                     {shouldShowErrorIcon && (
                        <TextFieldIcon as='span' variant='error'>
                           <MdWarning />
                        </TextFieldIcon>
                     )}
                     {props.type === 'password' && (
                        <PasswordViewerToggle isActive={type === 'text'} type='button' onClick={() => setType(type === 'password' ? 'text' : 'password')}>
                           {type === 'password' ? <MdVisibilityOff /> : <MdVisibility />}
                        </PasswordViewerToggle>
                     )}
                  </InputWrapper>
                  {!shouldShowErrorIcon && meta.touched && meta.error && <ErrorMessage>{meta.error}</ErrorMessage>}
               </FormField>
            );
         }}
      </Field>
   );
};

export const NumberField = ({ label, placeholder, suffix, max, ...props }) => {
   const [type, setType] = useState(props.type);
   const [field, meta, { setValue }] = useField(props);
   const latestValue = useRef(field.value);
   latestValue.current = field.value;

   const handleChange = e => {
      e.preventDefault();
      const normalized = normalizeNumber(e.target.value, latestValue.current);
      if (isNumber(max) && Number(normalized) > max) {
         return;
      }
      setValue(normalized);
   };

   return (
      <FormField hasError={meta.touched && meta.error}>
         {label && <FormLabel>{label}</FormLabel>}
         <div style={{ position: 'relative', display: 'flex' }}>
            <Input type={type} placeholder={placeholder} {...field} onChange={handleChange} hasSuffix={Boolean(suffix)} />
            {suffix && <InputSuffix>{suffix}</InputSuffix>}
         </div>
         {meta.touched && meta.error && <ErrorMessage>{meta.error}</ErrorMessage>}
      </FormField>
   );
};

export const SegmentedControlsField = ({ name, label, onChange, ...props }) => (
   <Field name={name}>
      {({ field, form: { touched, errors, setFieldValue }, meta }) => (
         <FormField>
            {label && <FormLabel>{label}</FormLabel>}
            <SegmentedControls
               onSelect={value => {
                  setFieldValue(name, value);
                  if (Boolean(onChange)) {
                     onChange(value);
                  }
               }}
               activeValue={field.value}
               {...props}
            />
            {meta.touched && meta.error && <ErrorMessage>{meta.error}</ErrorMessage>}
         </FormField>
      )}
   </Field>
);

export const CheckboxField = ({ name, ...props }) => (
   <Field name={name}>
      {({ field, form: { touched, errors, setFieldValue, setFieldTouched }, meta }) => (
         <FormField>
            <Checkbox
               {...props}
               onChange={e => {
                  setFieldValue(name, e.target.checked);
               }}
               checked={field.value}
            />
            {meta.touched && meta.error && <ErrorMessage>{meta.error}</ErrorMessage>}
         </FormField>
      )}
   </Field>
);

export const AttachmentsField = ({ optional, multiple, mode, initialAttachments, label, isUsingGraphql, ...props }) => {
   const { t } = useTranslation('misc');
   const [attachments, setAttachments] = useState([]);
   const attachmentRef = useRef();

   const getId = attachment => (isUsingGraphql ? attachment?.uuid : attachment?.id);

   const handleAddAttachment = (attachment, { setFieldValue }) => {
      if (!multiple) {
         setAttachments([attachment]);
         setFieldValue(field.name);
      }
   };

   const handleDeleteAttachment = id => {
      setAttachments(attachments.filter(attachment => attachment.id !== id));
   };

   return (
      <Field {...props}>
         {({ field, form, meta }) => (
            <FormField>
               {label && <FormLabel style={{ marginBottom: -8, display: 'block' }}>{label}</FormLabel>}
               <Attachments
                  post={props.post}
                  onAdd={attachment => {
                     const newAttachments = multiple ? [...attachments, attachment] : [attachment];
                     const newFieldValue = multiple ? newAttachments.map(getId) : getId(newAttachments[0]);

                     setAttachments(newAttachments);
                     form.setFieldValue(field.name, newFieldValue);
                  }}
                  onDelete={id => {
                     const newAttachments = multiple ? attachments.filter(attachment => attachment.id !== id) : [];
                     const newFieldValue = multiple ? newAttachments.map(getId) : getId(newAttachments[0]);
                     setAttachments(newAttachments);
                     form.setFieldValue(field.name, newFieldValue);
                  }}
                  attachments={attachments}
                  canBeRemoved
                  single
               />
               {meta.touched && meta.error && <ErrorMessage>{meta.error}</ErrorMessage>}
            </FormField>
         )}
      </Field>
   );
};

const TextareaWrapper = styled.div`
   display: flex;
   align-items: center;

   svg {
      width: 40px;
      height: 40px;
      color: ${({ theme }) => theme.primary};
   }

   textarea {
      flex: 1;
   }
`;

export const TextareaField = ({ label, type, placeholder, minRows, maxRows, disabled, icon, ...props }) => {
   return (
      <Field {...props}>
         {({ field, meta }) => (
            <FormField hasError={meta.touched && meta.error}>
               {label && <FormLabel>{label}</FormLabel>}
               <TextareaWrapper>
                  {icon}
                  <Textarea type={type} placeholder={placeholder} minRows={minRows} maxRows={maxRows} disabled={disabled} {...field} />
               </TextareaWrapper>
               {meta.touched && meta.error && <ErrorMessage>{meta.error}</ErrorMessage>}
            </FormField>
         )}
      </Field>
   );
};

const renderRadio = ({ field: { name, value, onChange, onBlur }, id, label, className, ...props }) => (
   <FormLabel htmlFor={id}>
      <input name={name} id={id} type='radio' value={id} checked={Number(id) === Number(value)} onChange={onChange} onBlur={onBlur} {...props} style={{ display: 'none' }} />
      {Number(id) === Number(value) ? <MdRadioButtonChecked color={theme.primary} /> : <MdRadioButtonUnchecked color={theme.primary} />} {label}
   </FormLabel>
);

export const RadioButtonField = props => (
   <Field {...props}>
      {({ field: { name, value, onChange, onBlur }, meta }) => (
         <FormLabel>
            <input
               name={name}
               type='radio'
               value={id}
               checked={id === value || Number(id) === Number(value)}
               onChange={onChange}
               onBlur={onBlur}
               {...props}
               style={{ display: 'none' }}
            />
            {Number(id) === Number(value) ? <MdRadioButtonChecked color={theme.primary} /> : <MdRadioButtonUnchecked color={theme.primary} />} {label}
         </FormLabel>
      )}
   </Field>
);

export const RadioButtonGroup = ({ value, error, touched, id, label, children }) => (
   <Fieldset>
      {Boolean(label) && <legend>{label}</legend>}
      {children}
   </Fieldset>
);

export const SearchInput = '';
export const ToggleFormLabel = '';
export const InputSearch = '';

export const DatePickerWrapper = styled.div`
   .react-date-picker__wrapper {
      width: ${({ theme }) => theme.spacing(25)};
      border: 1px solid ${({ theme }) => theme.separator};
      border-radius: 8px;
      height: 48px;
      padding: ${({ theme }) => theme.spacing(0.5)};
   }

   .react-date-picker__inputGroup {
      flex: 1;
   }
`;

export const SelectField = ({ inputValue, ...props }) => (
  <Field {...props}>
    {({ field: { name, value }, meta, form }) => {
      useDeepCompareEffect(() => {
        if (!Boolean(inputValue)) {
          return;
        }

        form.setFieldValue(field.name, inputValue);
      }, [{ inputValue }]);

      return (
        <FormField>
          <Select
            {...props}
            hasError={meta.touched && meta.error}
            onChange={({ value }) => {
              form.setFieldValue(name, value);
            }}
            value={value}
            variant="input"
          />

          {meta.touched && meta.error && (
            <ErrorMessage>{meta.error}</ErrorMessage>
          )}
        </FormField>
      );
    }}
  </Field>
);
