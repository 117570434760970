import { get, post, put } from './';
import { SERGIC_OFFER } from '../constants';
import {
   agency,
   budget,
   coownershipDocument,
   coownershipFolder,
   equipment,
   fiscalYear,
   identity,
   maintenanceContract,
   maintenanceContractsCategory,
   place,
   report,
   reportCategory,
   rssFeed,
   treasury,
   user,
} from './schema';
import * as Api from './v2';

export const getPlaces = () => get(`/v1/users/me/places?by_sergic_offer=${SERGIC_OFFER}`, { schema: [place] });
export const getPlace = id => get(`/v1/places/${id}`, { schema: place });
export const getPlaceV2 = id => Api.get(`/v2/places/${id}`);
export const updatePlace = (id, payload) => put(`/v1/places/${id}`, payload, { schema: place });
export const getPlaceNotificationsCount = id => get(`/v1/users/me/places/${id}/notifications_count`, { schema: place });

export const getPlaceEquipments = id => get(`/v1/places/${id}/equipments`, { schema: [equipment] });
export const getPlaceContacts = ({ queryKey }) =>
   Api.get(`/v1/places/${queryKey?.[1]}/contacts`, { params: queryKey?.[2] });
export const searchPlaceContacts = (id, query) => get(`/v1/places/${id}/contacts?${query}`, { schema: [user] });
export const searchPlaceContactsV2 = (placeId, params) => Api.get(`/v1/places/${placeId}/contacts`, { params });
export const getPlaceConversations = id => get(`/v1/places/${id}/conversations`);
export const getPlaceReports = id => get(`/v1/places/${id}/reports?by_state=acknowledged`, { schema: [report] });

export const postPlaceConversation = (id, payload) => post(`/v1/places/${id}/conversations`, payload);

export const getEquipment = id => get(`/v1/equipments/${id}`, { schema: equipment });
export const getEquipmentReports = id => get(`/v1/equipments/${id}/reports`, { schema: [report] });

export const getPlaceTreasuries = id => get(`/v1/places/${id}/treasuries`, { schema: [treasury] });
export const getPlaceFiscalYears = (id, ioni) => get(`/v2/places/${id}/fiscal_years`, { schema: [fiscalYear], ioni });
export const getPlaceBudgets = (placeId, fiscalYearId, ioni) =>
   get(`/v2/budgets?place_id=${placeId}${fiscalYearId ? `&fiscal_year_id=${fiscalYearId}` : ''}`, {
      schema: [budget],
      ioni,
   });
export const getPlaceMaintenanceWorks = id => get(`/v1/places/${id}/maintenance_works`);
export const getPlaceCoownershipFolders = ({ sliceId, params }) =>
   get(`/v2/places/${sliceId}/coownership_folders?${params}`, { schema: [coownershipFolder] });
export const getCoownershipFolderDocuments = ({ sliceId, params }) =>
   get(`/v2/places/${sliceId}/coownership_documents?${params}`, { schema: [coownershipDocument] });
export const getPlaceMaintenanceContractsCategories = id =>
   get(`/v1/places/${id}/maintenance_contract_categories`, { schema: [maintenanceContractsCategory] });
export const getPlaceMaintenanceContract = id =>
   get(`/v1/maintenance_contracts/${id}`, { schema: maintenanceContract });

export const getPlaceFeed = placeId => get(`/v1/places/${placeId}/feed_events`);
export const getPlaceWorkOrders = placeId => get(`/v1/places/${placeId}/feed_events?by_kind=work_order`);

export const getShareableRoles = () => get(`/v1/roles?filter=for_invitation`);
export const getPlaceInvitations = placeId => get(`/v1/places/${placeId}/invitations`);

export const getPlaceAgency = placeId => get(`/v1/places/${placeId}/agency`, { schema: agency });
export const getPlaceNotifications = ({ placeId, params }) =>
   Api.get(`/v1/places/${placeId}/place_actualities`, { params });
export const getNotification = notificationId => Api.get(`/v1/place_actualities/${notificationId}`);

export const getReportCategories = () => get(`/v1/report_categories`, { schema: [reportCategory] });

export const getDistributionLists = placeId => Api.get(`/v1/places/${placeId}/distribution_lists`);

export const postPlaceDefaultIdentities = (placeId, payload) =>
   post(`/v1/users/me/places/${placeId}/default_identities`, payload, { schema: identity });

export const getPlaceRSSFeeds = ({ placeId }) => Api.get(`/v1/places/${placeId}/rss_feeds`);
export const getPlaceRSSFeedsOld = placeId => get(`/v1/places/${placeId}/rss_feeds`, { schema: [rssFeed] });
export const getRssFeed = feedId => Api.get(`/v1/rss_feeds/${feedId}`);

export const getFiscalYears = ({ sliceId, params }) =>
   get(`/v1/places/${sliceId}/fiscal_years${params}`, { schema: [fiscalYear] });

export const getPlacesNew = ({ params }) => get(`/v1/places${params}`, { schema: [place] });
export const postPlaceRealEstateProject = ({ placeId, payload }) =>
   Api.post(`/v2/places/${placeId}/real_estate_projects`, payload);
