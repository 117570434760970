import styled, { css } from 'styled-components';
import { Box, Stack } from './base';
import { media } from '@tymate/margaret';

export const Card = styled(Box)`
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.separator};
  border-radius: ${({ theme }) => theme.borderRadius};
  background-color: #fff;
`;

Card.defaultProps = {
  padding: 1,
  direction: 'column',
};

export const CardTitle = styled(Stack)`
  font-size: 1.189rem;
  font-weight: 600;

  ${({ variant }) =>
    variant === 'primary' &&
    css`
      color: ${({ theme }) => theme.primary};
    `}
`;

CardTitle.defaultProps = {
  as: 'h3',
  alignY: 'center',
  marginBottom: 0.25,
  marginTop: '0',
  gutterSize: 'tiny',
};

export const CardElement = styled.div`
  background-color: #fff;
  border-radius: ${({ theme }) => theme.borderRadius};
  border: 1px solid ${({ theme }) => theme.separator};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing()};
  color: inherit;
  text-decoration: none;
  transition: box-shadow 100ms ease;

  ${props =>
   props.to &&
   css`
       &:hover {
         box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12);
       }
     `};

  > *:last-child {
    margin-bottom: 0;
  }

  ${props =>
   props.isSemiPadded &&
   css`
       padding: ${({ theme }) => theme.spacing()};
     `};

  ${props =>
   props.hasSmallPadding &&
   css`
       padding: ${({ theme }) => theme.spacing(0.5)};
     `};

  ${props =>
   props.isPadded &&
   css`
       padding: ${({ theme }) => theme.spacing(1.5)};
     `};

  ${props =>
   props.hasNoHeader &&
   css`
       padding-top: ${({ theme }) => theme.spacing()};
     `};

  ${props =>
   (props.isMargedBottom || props.isMargedVertically) &&
   css`
       margin-bottom: ${({ theme }) => theme.spacing(2)};
     `};

  ${props =>
   (props.isMargedTop || props.isMargedVertically) &&
   css`
       margin-top: ${({ theme }) => theme.spacing(2)};
     `};

  ${props =>
   props.hasNoBottomMargin &&
   css`
       margin-bottom: 0;
     `};
`;

export const CardElementContent = styled.article`
   flex: 0 0 100%;
   padding: 0 ${({ theme }) => theme.spacing()} ${({ theme }) => theme.spacing()};

   ${media.tablet`
    padding: 0 ${({ theme }) => theme.spacing(1.5)} ${({ theme }) => theme.spacing()};
  `};

   ${props =>
   props.hasEqualPadding &&
   css`
         padding: ${({ theme }) => theme.spacing()};

         ${media.tablet`padding: ${({ theme }) => theme.spacing()}`};
      `};

   ${props =>
   props.isBare &&
   css`
         padding: 0;

         ${media.tablet`padding: 0;`};
      `};

   ${props =>
   props.variant === 'bareBottom' &&
   css`
         padding-bottom: 0;

         ${media.tablet`
        padding-bottom: 0;
      `};
      `};

   + article {
      border-top: 1px solid ${({ theme }) => theme.separator};
   }
`;

export const CardElementHeader = styled.header`
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
   flex: 0 0 100%;
   padding: ${({ theme }) => theme.spacing()};

   ${media.tablet`
    padding: ${({ theme }) => theme.spacing()} ${({ theme }) => theme.spacing(1.5)};
  `};

   ${props =>
   props.hasBorderBottom &&
   css`
         border-bottom: 1px solid ${({ theme }) => theme.separator};
      `};

   ${props =>
   props.isSemiPadded &&
   css`
         padding-left: ${({ theme }) => theme.spacing()};
         padding-right: ${({ theme }) => theme.spacing()};
      `};
`;

export const CardTitleAlt = styled(CardTitle)`
   text-transform: none;
   font-weight: 600;
   margin-bottom: 0;
`;

export const CardHeaderMeta = styled.div`
   flex: 1;
`;
