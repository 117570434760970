import URLQueryBuilder from 'url-query-builder';
import * as Api from 'api';

class Paginator {
   constructor(uri, entity, page = 1, perPage = 15) {
      this.uri = uri;
      this.entity = entity;

      this.queryBuilder = new URLQueryBuilder(this.uri, {
         page: page,
         per_page: perPage,
      });
   }

   reset = () => {
      this.queryBuilder.change('page', 1);
      return this;
   };

   set = (param, value) => {
      this.queryBuilder.change(param, value);
      return this;
   };

   getUrl = () => this.queryBuilder.getUrl();

   getQueries = () => this.queryBuilder.queries;

   getPage = () => this.queryBuilder.queries.page;

   get = (page = this.getPage()) => {
      console.log('PAGINATOR', this.getQueries());
      this.queryBuilder.change('page', page);
      return Api.get(this.getUrl(), { schema: this.entity, params: this.getQueries() });
   };

   getMore = () => this.get(this.getPage() + 1);
}

export default Paginator;
