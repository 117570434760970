import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import styled from 'styled-components';
import Animate from 'react-move/Animate';
import { ButtonReset } from 'ui';
import { PopoverContainer } from 'ui/popover';
import { useLocation } from 'react-use';
import { get } from 'lodash';

const Wrapper = styled.div`
   position: relative;
   display: flex;
`;

const useOnClickOutside = (ref, handler) => {
   useEffect(() => {
      const listener = event => {
         if (!ref.current || ref.current.contains(event.target)) {
            return;
         }
         handler();
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      return () => {
         document.removeEventListener('mousedown', listener);
         document.removeEventListener('touchstart', listener);
      };
      // eslint-disable-next-line
   }, []);
};

const Dropdown = (
   {
      trigger,
      triggerIcon,
      children,
      top,
      right,
      onToggle,
      wrapperStyle,
      containerStyle,
      disabled,
      shouldCloseDropdownOnTriggerClick,
      ...props
   },
   ref
) => {
   const containerRef = useRef();
   const [isOpen, setIsOpen] = useState(false);
   const location = useLocation();
   const { key } = get(location, 'state.key') || location.pathname;

   const close = () => setIsOpen(false);
   const open = () => setIsOpen(true);

   useImperativeHandle(ref, () => ({
      close,
      open,
      isOpen,
   }));

   useOnClickOutside(containerRef, close);
   useEffect(() => {
      close();
      // eslint-disable-next-line
   }, [key]);

   useEffect(() => {
      if (!onToggle) {
         return;
      }
      onToggle(isOpen);
      // eslint-disable-next-line
   }, [isOpen]);

   return (
      <Wrapper ref={containerRef} style={{ ...(wrapperStyle || {}) }}>
         <ButtonReset
            type='button'
            style={{ textAlign: 'left', width: '100%', display: 'block' }}
            onClick={e => {
               e.preventDefault();
               if (disabled) {
                  return;
               }

               setIsOpen(shouldCloseDropdownOnTriggerClick ? !isOpen : true);
            }}
         >
            {trigger}
         </ButtonReset>

         <Animate
            show={isOpen}
            start={{ opacity: 0, translate: 10, display: 'none' }}
            enter={[
               {
                  opacity: [1],
                  timing: {
                     duration: 100,
                  },
                  display: 'block',
               },
               {
                  translate: [0],
                  timing: {
                     duration: 100,
                  },
               },
            ]}
            leave={[
               {
                  opacity: [0],
                  timing: {
                     duration: 100,
                  },
                  display: 'none',
               },
               {
                  translate: [10],
                  timing: {
                     duration: 100,
                  },
               },
            ]}
         >
            {({ opacity, translate, display }) => (
               <PopoverContainer
                  {...props}
                  top={top}
                  right={right}
                  style={{
                     opacity,
                     transform: `translateY(${(top ? 1 : -1) * translate}px)`,
                     display,
                     ...containerStyle,
                  }}
               >
                  {children}
               </PopoverContainer>
            )}
         </Animate>
      </Wrapper>
   );
};

export default forwardRef(Dropdown);
