import get from 'lodash/get';

const initialState = {
   isActive: false,
   isFetching: false,
   hasFailed: false,
   data: {},
};

const communication = (state = initialState, action) => {
   const { type, payload } = action;

   switch (type) {
      case 'MAINTENANCE_COMMUNICATION_REQUEST':
         return {
            ...state,
            hasFailed: false,
            isFetching: true,
         };

      case 'MAINTENANCE_COMMUNICATION_SUCCESS':
         return {
            ...state,
            isFetching: false,
            data: { ...get(payload, 'data') },
         };

      case 'MAINTENANCE_COMMUNICATION_ERROR':
         return {
            ...state,
            hasFailed: true,
            isFetching: false,
         };
      default:
         return state;
   }
};
export default communication;
